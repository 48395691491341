<template>
  <v-dialog
    :value="dialog"
    width="616"
    style="z-index: 20001"
    persistent
    @click:outside="toggleDialog"
  >
    <v-card class="invite-user-modal pa-5">
      <v-card-title class="text-h5 lighten-2 pa-0">
        {{ modalData.title }}
      </v-card-title>

      <v-card-text class="pa-0">
        {{ modalData.description }}
      </v-card-text>

      <v-card-actions class="pa-0 pt-8 d-flex justify-end">
        <v-btn
          color="primary"
          text
          normal
          :disabled="loading"
          @click="toggleDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="!actionType"
          :loading="loading"
          color="primary"
          rounded
          normal
          variant="elevated"
          @click="onSubmit"
        >
          {{ modalData.submitButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdminManagementActions } from "../../misc/constants";

export default {
  name: "UserActionModal",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: String,
      default: AdminManagementActions.deactivateUser,
    },
  },

  data() {
    const textData = {
      [AdminManagementActions.deactivateUser]: {
        title: "Deactivate user",
        description: "Are you sure you want to deactivate a user?",
        submitButtonText: "Deactivate",
      },
      [AdminManagementActions.activateUser]: {
        title: "Activate user",
        description: "Are you sure you want to activate a user?",
        submitButtonText: "Activate",
      },
    };

    return {
      loading: false,
      textData,
    };
  },

  computed: {
    modalData() {
      return this.textData[this.actionType] || {};
    },
  },

  methods: {
    toggleDialog() {
      this.$emit("toggle", !this.dialog);
    },
    onSubmit() {
      this.loading = true;
      this.$emit(
        "submit",
        function () {
          this.loading = false;
        }.bind(this)
      );
    },
  },
};
</script>
