<template>
  <v-text-field
    :value="value"
    outlined
    hide-details
    :label="label"
    prepend-inner-icon="mdi-magnify"
    clearable
    @input="handleChange"
    @click:clear="$emit('change', null)"
  />
</template>

<script>
import { debounce } from "lodash";

export default {
  name: "SearchInput",

  props: {
    value: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "Search",
    },
  },

  methods: {
    handleChange: debounce(function (value) {
      this.$emit("change", value);
    }, 500),
  },
};
</script>
