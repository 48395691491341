<template>
  <v-dialog
    :value="dialog"
    width="616"
    style="z-index: 20001"
    persistent
    @click:outside="toggleDialog"
  >
    <v-card class="invite-user-modal pa-5">
      <v-card-title class="text-h5 lighten-2 pa-0">
        Invite new user
      </v-card-title>

      <v-form class="pt-5">
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          type="email"
          class="mb-2"
          clearable
          outlined
          label="Email address"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        />
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          type="text"
          clearable
          label="Name"
          outlined
          placeholder="Enter your name"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />
        <v-select
          v-model="role"
          :items="userRoleItems"
          :error-messages="roleErrors"
          :menu-props="{ bottom: true, offsetY: true }"
          label="Role"
          clearable
          hide-details
          outlined
          @change="$v.role.$touch()"
          @blur="$v.role.$touch()"
        />
      </v-form>

      <v-card-actions class="pa-0 pt-8 d-flex justify-end">
        <v-btn
          color="primary"
          text
          normal
          :disabled="loading"
          @click="toggleDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="$v.$invalid"
          :loading="loading"
          color="primary"
          rounded
          normal
          variant="elevated"
          @click="onSubmit"
        >
          Invite
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "InviteNewUserModal",

  mixins: [validationMixin],

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    userRoleItems: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      email: null,
      name: null,
      role: null,
    };
  },

  validations: {
    email: { required, email },
    name: { required, minLength: minLength(5) },
    role: { required },
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      !this.$v.name.minLength &&
        errors.push("The name must have a minimum 5 characters");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.role.$dirty) return errors;
      !this.$v.role.required && errors.push("Role is required");
      return errors;
    },
  },

  methods: {
    toggleDialog() {
      this.$emit("toggle", !this.dialog);
      this.resetData();
    },
    onSubmit() {
      this.$emit("submit", {
        name: this.name,
        email: this.email.toLowerCase(),
        role: this.role,
      });
    },
    resetData() {
      this.name = null;
      this.email = null;
      this.role = null;
      this.$v.$reset();
    },
  },
};
</script>
